import React from "react";
import clsx from "clsx";
import MUIButton from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import MUITooltip from "@material-ui/core/Tooltip";

const useButtonStyles = makeStyles((theme) => ({
	root: {
	},
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
		},
	},
	secondary: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
			color: theme.palette.secondary.contrastText,
		},
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
			color: theme.palette.error.contrastText,
		},
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.success.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
		},
	},
}));
const useIconButtonStyles = makeStyles((theme) => ({
	root: {
	},
	primary: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	secondary: {
		color: theme.palette.secondary.main,
		'&:hover': {
			color: theme.palette.secondary.dark,
		},
	},
	error: {
		color: theme.palette.error.main,
		'&:hover': {
			color: theme.palette.error.dark,
		},
	},
	success: {
		color: theme.palette.success.main,
		'&:hover': {
			color: theme.palette.success.dark,
		},
	},
}));

const Tooltip = ({tooltip, children}) => {
	if (_.isEmpty(tooltip)) return children;

	return (
		<MUITooltip title={tooltip}>
			<span>
				{children}
			</span>
		</MUITooltip>
	);
};

const Button = React.forwardRef(({to, variant = 'contained', color = 'primary', Icon, children, ...props}, ref) => {
	const buttonClasses = useButtonStyles();
	const iconButtonClasses = useIconButtonStyles();

	if (_.isEmpty(children) && !_.isEmpty(Icon)) {
		return (
			<IconButton
				ref={ref}
				component={to ? Link : undefined}
				to={to}
				{...props}
				className={clsx(iconButtonClasses.root, {
					[iconButtonClasses.primary]: color === "primary",
					[iconButtonClasses.secondary]: color === "secondary",
					[iconButtonClasses.error]: color === "error",
					[iconButtonClasses.success]: color === "success",
				})}
			>
				{React.cloneElement(Icon, {style: {color: 'inherit'}})}
			</IconButton>
		);
	}

	return (
		<MUIButton
			ref={ref}
			component={to ? Link : undefined}
			to={to}
			variant={variant}
			{...props}
			className={clsx(buttonClasses.root, {
				[buttonClasses.primary]: color === "primary",
				[buttonClasses.secondary]: color === "secondary",
				[buttonClasses.error]: color === "error",
				[buttonClasses.success]: color === "success",
			})}
		>
			{children}
		</MUIButton>
	);
});

export default (props) => (
	<Tooltip {...props}>
		<Button {...props}/>
	</Tooltip>
);

Button.propTypes = {
	...MUIButton.propTypes,
	Icon: PropTypes.node,
	color: PropTypes.oneOf(['primary', 'secondary', 'error', 'success']),
};
