/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import {mockAxios, setupAxios} from "./_metronic";
import store, {persistor} from "./app/store/store";
import App from "./App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';

_.mixin({
	'move': function (array, moveIndex, toIndex) {
		let itemRemovedArray = [
			...array.slice(0, moveIndex),
			...array.slice(moveIndex + 1, array.length)
		];
		return [
			...itemRemovedArray.slice(0, toIndex),
			array[moveIndex],
			...itemRemovedArray.slice(toIndex, itemRemovedArray.length)
		];
	},
	'qs': function(obj) {
		let parts = [];
		for (let i in obj) {
			if (obj.hasOwnProperty(i)) {
				parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
			}
		}
		return parts.join("&");
	}
});


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);


ReactDOM.render(
	<App
		store={store}
		persistor={persistor}
		basename={PUBLIC_URL}
	/>,
	document.getElementById("root")
);

