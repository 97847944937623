import FormControlLabel from "@material-ui/core/FormControlLabel";
import MUICheckbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 0,
	},
	noMargin: {
		margin: 0,
	},
}));

function Checkbox({label, labelPlacement = 'end', margin = 'default', readOnly = false, disabled = false, ...props}) {
	const classes = useStyles();

	return (
		<FormControlLabel
			className={clsx(classes.root, {
				[classes.noMargin]: margin === 'none',
			})}
			control={
				<MUICheckbox
					{...props}
				/>
			}
			label={label}
			labelPlacement={labelPlacement}
			readOnly={readOnly}
			disabled={disabled}
		/>
	);
}

Checkbox.propTypes = {
	margin: PropTypes.oneOf(['default', 'none']),
};

export default Checkbox;
