import React from 'react';
import {render} from "react-dom";
import _ from "lodash";
import Dialog from "widgets/Dialog.js";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "widgets/Button.js";
import DialogActions from "@material-ui/core/DialogActions";

class Confirm extends React.Component {
	static ref = React.createRef();

	state = {
		dialogs: [],
	};

	static open(...params) {
		return Confirm.ref.current._open(...params);
	};

	_open = (options) => {
		if (_.isString(options)) {
			options = {
				title: options,
			};
		}
		return new Promise((resolve, reject) => {
			const {dialogs} = this.state;

			dialogs.push({
				id: _.uniqueId(),
				open: true,
				resolve,
				reject,
				options,
			});

			this.setState({dialogs});
		});
	};

	_close = (id, success = false) => () => {
		const {dialogs} = this.state;

		this.setState({
			dialogs: _.map(dialogs, (dialog) => {
				if (dialog.id === id) {
					dialog.open = false;

					_.invoke(dialog, success ? 'resolve' : 'reject');
				}

				return dialog;
			}),
		});
	};

	_exit = (id) => () => {
		const {dialogs} = this.state;

		_.remove(dialogs, (dialog) => dialog.id === id);

		this.setState({dialogs});
	};

	render() {
		const {dialogs} = this.state;

		return _.map(dialogs, ({options, ...dialog}) => {
			return (
				<Dialog
					key={dialog.id}
					title={options.title}
					open={dialog.open}
					onExited={this._exit(dialog.id)}
				>
					<DialogContent>
						<Typography variant={'h4'}>{options.message}</Typography>
					</DialogContent>
					<DialogActions>
						<Button color={'primary'} onClick={this._close(dialog.id)}>No</Button>
						<Button color={'error'} onClick={this._close(dialog.id, true)}>Yes</Button>
					</DialogActions>
				</Dialog>
			)
		});
	}
}

export default Confirm;
