import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import {connect} from "react-redux";
import _ from "lodash";

class MenuList extends React.Component {
	render() {
		const {currentUrl, menuConfig, layoutConfig, auth} = this.props;

		return menuConfig.aside.items.map((child, index) => {
			if (_.isFunction(child.visible)) {
				if (!child.visible(auth)) return null;
			}

			return (
				<React.Fragment key={`menuList${index}`}>
					{child.section && <MenuSection item={child}/>}
					{child.separator && <MenuItemSeparator item={child}/>}
					{child.title && (
						<MenuItem
							item={child}
							currentUrl={currentUrl}
							layoutConfig={layoutConfig}
						/>
					)}
				</React.Fragment>
			);
		});
	}
}

export default connect(({auth}) => ({auth}))(MenuList);
