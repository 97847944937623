import React from "react";
// import {Dashboard, Folder, Schedule, Storage} from "@material-ui/icons";
import GroupIcon from '@material-ui/icons/Group';
import _ from "lodash";
import {
	Dashboard,
	FlashOn,
	Folder,
	LibraryMusic,
	OfflineBolt,
	Schedule,
	Settings,
	Speed,
	Storage,
	List,
	AccountBox, GraphicEq
} from "@material-ui/icons";

export default {
	header: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				alignment: "left",
				page: "dashboard",
				translate: "MENU.DASHBOARD"
			},
			{
				title: "Archive",
				root: true,
				alignment: "left",
				page: "archive",
				translate: "MENU.ARCHIVE"
			},
			{
				title: "Users",
				root: true,
				alignment: "left",
				page: "users",
				translate: "MENU.USERS"
			},
			{
				title: "Macro",
				root: true,
				alignment: "left",
				page: "macro",
				translate: "MENU.MACRO"
			},
			{
				title: "Scheduler",
				root: true,
				alignment: "left",
				page: "scheduler",
				translate: "MENU.SCHEDULER"
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "DASHBOARD",
				translate: "MENU.DASHBOARD",
				root: true,
				icon: <Dashboard/>,
				page: "/dashboard",
			},
			{
				title: "FILEMANAGER",
				translate: "MENU.FILEMANAGER",
				root: true,
				icon: <LibraryMusic/>,
				page: "/archive",
				bullet: "dot",
				submenu: [
					// {
					// 	title: "WAITING",
					// 	translate: "MENU.FILEMANAGER.WAITING",
					// 	page: "/archive/waiting",
					// },
					{
						title: "CLIPS",
						translate: "MENU.FILEMANAGER.CLIPS",
						page: "/archive/clip",
					},
					{
						title: "INTRO",
						translate: "MENU.FILEMANAGER.INTRO",
						page: "/archive/intro",
					},
					{
						title: "BASE",
						translate: "MENU.FILEMANAGER.BASE",
						page: "/archive/base",
					},
					{
						title: "OUTRO",
						translate: "MENU.FILEMANAGER.OUTRO",
						page: "/archive/outro",
					},
					{
						title: "SEPARATORS",
						translate: "MENU.FILEMANAGER.SEPARATORS",
						page: "/archive/separator",
					},
					{
						title: "MASHUP",
						translate: "MENU.FILEMANAGER.MASHUP",
						page: "/archive/mashup",
					},
				],
			},
			{
				title: "USERS",
				root: true,
				icon: <GroupIcon/>,
				page: "/users/list",
				translate: "MENU.USERS",
				visible: ({user}) => {
					const roles = _.map(user.roles, ({role}) => _.toLower(role));

					return _.includes(roles, 'admin') || _.includes(roles, 'reseller') || _.includes(roles, 'user');
				},
			},
			{
				title: "CREATE",
				translate: "MENU.CREATE",
				root: true,
				icon: <FlashOn/>,
				page: "/create",
				submenu: [
					{
						title: "WIZARD",
						translate: "MENU.CREATE.WIZARD",
						page: "/create/wizard",
						icon: <Speed/>
					},
				],
			},
			{
				title: "AUTOMATION",
				translate: "MENU.SETTINGS",
				root: true,
				icon: <Settings/>,
				submenu: [
					{
						title: "CATEGORIES",
						translate: "MENU.SETTINGS.CATEGORIES",
						icon: <Folder/>,
						page: "/categories",
					},
					{
						title: "MACRO",
						translate: "MENU.SETTINGS.MACRO",
						icon: <Storage/>,
						page: "/macro",
					},
					{
						title: "SCHEDULES",
						translate: "MENU.SETTINGS.SCHEDULES",
						icon: <Schedule/>,
						page: "/schedules",
					},
					{
						title: "FTP TARGETS",
						// translate: "MENU.SETTINGS.SCHEDULES",
						icon: <AccountBox/>,
						page: "/ftp_targets/list",
						visible: ({user}) => {
							const roles = _.map(user.roles, ({role}) => _.toLower(role));

							return _.includes(roles, 'admin') || _.includes(roles, 'reseller') || _.includes(roles, 'user');
						},
					},
					{
						title: "AI VOICES",
						// translate: "MENU.SETTINGS.SCHEDULES",
						icon: <GraphicEq/>,
						page: "/ai/voices",
					},
				],
			},
		]
	}
};
