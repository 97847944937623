import React from "react";
import _ from "lodash";
import {useSortBy, useTable, useGlobalFilter, usePagination} from 'react-table'
import {ArrowDropDown, ArrowDropUp, TableChart} from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {MenuItem} from "@material-ui/core";
import Cookies from 'js-cookie';
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "widgets/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

const style = (theme) => ({
	info: {
		color: theme.palette.text.secondary,
	},
	page: {
		color: theme.palette.text.secondary,
	},
	pageField: {
		marginTop: 0,
		marginBottom: 0,
		marginLeft: 4,
		marginRight: 4,
	},
	footerInfo: {
		justifyContent: 'flex-start',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			textAlign: 'center',
		},
	},
	footerPagination: {
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			textAlign: 'center',
		},
	},
	footerPageLength: {
		justifyContent: 'flex-end',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			textAlign: 'center',
		},
	}
});

const useStyles = makeStyles(style);

const TableInfo = ({data, size, pages, page}) => {
	const classes = useStyles();

	let from = page*size;
	let to = (page*size)+size;
	let length = _.get(data, 'length', 0);

	let empty = length === 0;

	return (
		<span className={classes.info}>Showing rows from {empty ? 0 : Math.max(from, 1)} to {Math.min(to, length)} of {length}</span>
	);
};

const TablePage = ({length, onChange}) => {
	const classes = useStyles();

	const handleChange = React.useCallback(({target: {value}}) => {
		onChange(value);
	}, [onChange]);

	return (
		<Box display={'flex'} alignItems={'center'} className={classes.page}>
			Show
			<TextField
				select
				value={length}
				onChange={handleChange}
				className={classes.pageField}
				variant="outlined"
				size="small"
			>
				<MenuItem value={10}>10</MenuItem>
				<MenuItem value={25}>25</MenuItem>
				<MenuItem value={50}>50</MenuItem>
				<MenuItem value={100}>100</MenuItem>
			</TextField>
			rows
		</Box>
	);
};

const ColumnVisibilityToggle = ({columns, hiddenColumns, setHiddenColumns}) => {
	const [hidden, setHidden] = React.useState(hiddenColumns || []);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const close = () => {
		setAnchorEl(null);
	};

	const handleCheck = React.useCallback((id) => (e) => {
		e.preventDefault();
		const checked = _.includes(hidden, id);
		let ids = hidden;
		if (!checked) {
			ids = _.uniq([...hidden, id]);
		}
		else {
			ids = _.filter(hidden, (col) => col !== id);
		}
		setHiddenColumns(ids);
		setHidden(ids);
	}, [hidden, setHiddenColumns]);

	return (
		<>
			<Button Icon={<TableChart/>} tooltip={`Hide columns`} color={'primary'} onClick={open}/>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={close}
			>
				{_.map(columns, (column) => (
					<MenuItem key={column.id || column.accessor} style={{paddingTop: 0, paddingBottom: 0}} onClick={handleCheck(column.id || column.accessor)}>
						<FormControlLabel
							control={<Checkbox checked={!_.includes(hidden, column.id || column.accessor)} color={'primary'}/>}
							label={column.name}
							labelPlacement={'end'}
						/>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default function DataTable({id, data, filter, sort, Header, HeaderButtons, loading = false, showSearch = true, showInfo = true, showPageLength = true, conditionalRowStyles, ...props}) {
	const [tableFilter, setTableFilter] = React.useState();
	const cookie = Cookies.get('tables') ? JSON.parse(Cookies.get('tables')) : {};
	const classes = useStyles();

	const hiddenColumns = cookie[id];

	let {
		getTableProps,
		getTableBodyProps,
		columns,
		headerGroups,
		rows,
		page,
		prepareRow,
		setGlobalFilter,
		pageCount,
		gotoPage,
		canNextPage,
		canPreviousPage,
		state: {pageIndex, pageSize, globalFilter},
		setPageSize,
		setHiddenColumns,
	} = useTable(
		{
			columns: props.columns,
			data,
			initialState: _.pickBy({
				hiddenColumns,
				sortBy: sort,
			}, _.identity),
			sortTypes: {
				datetime: (a, b, column) => _.get(a.original, column, '').localeCompare(_.get(b.original, column, '')),
			},
			autoResetPage: false,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	React.useEffect(() => {
		setGlobalFilter(tableFilter);
	}, [tableFilter]);

	//region Props
	React.useEffect(() => {
		setTableFilter(filter);
	}, [filter]);
	//endregion

	const handlePageChange = React.useCallback((event, page) => {
		gotoPage(page-1);
	}, []);
	const handleFilterChange = React.useCallback(({target: {value}}) => {
		setTableFilter(value);
	}, []);

	const header = React.useMemo(() => {
		if (Header) return Header;

		return (
			<Box display={'flex'} alignItems={'center'}>
				<TextField
					value={globalFilter || ''}
					size={'small'}
					margin={'none'}
					onChange={handleFilterChange}
					placeholder={'Search...'}
					variant="outlined"
					hidden={!showSearch}
				/>
				{!_.isEmpty(id) && (
					<ColumnVisibilityToggle
						columns={columns}
						hiddenColumns={hiddenColumns}
						setHiddenColumns={(cols) => {
							Cookies.set('tables', JSON.stringify({
								...cookie,
								[id]: cols,
							}));
							setHiddenColumns(cols);
						}}
					/>
				)}
				<Box flexGrow={1}/>
				{HeaderButtons}
			</Box>
		)
	}, [Header, HeaderButtons, showSearch, globalFilter]);

	const theme = useTheme();

	return (
		<>
			{header}
			<div style={{overflowX: 'auto'}}>
			<table
				{...getTableProps([
					{
						className: 'table',
						style: {
							display: 'block',
							overflowX: 'auto',
							marginTop: theme.spacing(1),
							backgroundColor: theme.palette.background.paper,
							color: theme.palette.text.primary,
							borderWidth: 1,
							borderColor: '#ebedf2',
							borderStyle: 'solid',
							borderRadius: theme.shape.borderRadius,
						}
					}
				])}
			>
				<thead className={'table-head'}>
					{headerGroups.map(headerGroup => (
						<tr
							{...headerGroup.getHeaderGroupProps([
								{
									className: 'table-row',
								}
							])}
						>
							{headerGroup.headers.map(column => (
								<th
									{...column.getHeaderProps([
										column.getSortByToggleProps(),
										{
											className: _.join(_.compact(['table-cell', column.className]), ' '),
											style: {
												width: column.shrink ? '1%' : column.width,
												whiteSpace: !column.wrap ? 'nowrap' : '',
												textAlign: column.align || 'left',
											},
											title: '',
										},
									])}
								>
									{column.name && column.render('name')}
									{column.isSorted && (
										<>
											{column.isSortedDesc ? <ArrowDropDown/> : <ArrowDropUp/>}
										</>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody
					{...getTableBodyProps([
						{
							className: 'table-body',
						}
					])}
				>
					{page.length === 0 && (
						<tr className={'text-center text-muted'}>
							<td colSpan={_.get(_.first(headerGroups), 'headers.length', 1)}>
								{!_.isEmpty(tableFilter) ? 'No results' :
								loading ? 'Loading data' :
								'No data available'}
							</td>
						</tr>
					)}
					{page.map((row) => {
						prepareRow(row);

						const styles = _.compact(_.map(conditionalRowStyles, ({when, ...props}) => {
							if (when(row.original)) {
								return props;
							}
						}));

						return (
							<tr {...row.getRowProps([
								{
									className: 'table-row',
								},
								...styles,
							])}>
								{row.cells.map(({column, ...cell}) => {
									return (
										<td
											{...cell.getCellProps([
												{
													className: _.join(_.compact(['table-cell', column.className]), ' '),
													style: {
														width: column.shrink ? '1%' : column.width,
														whiteSpace: !column.wrap ? 'nowrap' : '',
														textAlign: column.align || 'left',
													},
												},
											])}
										>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			</div>
			<Grid container justifyContent={'space-between'} alignItems={'center'} spacing={1}>
				<Grid item xs={12} lg={4}>
					<Box display={'flex'} alignItems={'center'} className={classes.footerInfo}>
						{showInfo && (
							<TableInfo
								data={data}
								pages={pageCount}
								page={pageIndex}
								size={pageSize}
							/>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Box display={'flex'} alignItems={'center'} className={classes.footerPagination}>
						{pageCount > 0 && (
							<Pagination
								color={'primary'}
								count={pageCount}
								page={pageIndex+1}
								onChange={handlePageChange}
							/>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Box display={'flex'} alignItems={'center'} className={classes.footerPageLength}>
						{showPageLength && (
							<TablePage
								length={pageSize}
								onChange={setPageSize}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

// DataTable.propTypes = {
// 	...DataTableComponent.propTypes,
// };
