import React from "react";
import _ from "lodash";
import {ErrorPage1} from "./ErrorPage1";
import {ErrorPage2} from "./ErrorPage2";
import {ErrorPage3} from "./ErrorPage3";
import {ErrorPage4} from "./ErrorPage4";
import {ErrorPage5} from "./ErrorPage5";
import {ErrorPage6} from "./ErrorPage6";

export default function ErrorsPage() {
	// return _.sample([
	// 	<ErrorPage1/>,
	// 	<ErrorPage2/>,
	// 	<ErrorPage3/>,
	// 	<ErrorPage4/>,
	// 	<ErrorPage5/>,
	// 	<ErrorPage6/>
	// ]);
	return (
		<ErrorPage6/>
	);
}
