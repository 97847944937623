/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {connect} from "react-redux";
import {toAbsoluteUrl} from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Paper from "@material-ui/core/Paper";
import {AccountCircle} from "@material-ui/icons";
import Box from "@material-ui/core/Box";

class UserProfile extends React.Component {
	render() {
		const {user, showHi} = this.props;

		return (
			<Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
				<Dropdown.Toggle
					as={HeaderDropdownToggle}
					id="dropdown-toggle-user-profile"
				>
					<div className="kt-header__topbar-user">
						{showHi && (
							<span className="kt-header__topbar-welcome kt-hidden-mobile">
                				Hi,
              				</span>
						)}
						<span className="kt-header__topbar-username kt-hidden-mobile">
							{user.username}
					 	</span>

						{user.picture ? (
							<img alt="user_picture" src={user.picture}/>
						) : (
							<AccountCircle color={"inherit"}/>
						)}
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu
					className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
					{/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
					<div
						className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
						style={{
							backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
						}}
					>
						<div className="kt-user-card__name">{user.fullName || user.username}</div>
						<div className="kt-user-card__badge"/>
					</div>
					<div className="kt-notification">
						<Box p={2}>
							<Link
								to="/logout"
								className="btn btn-label-brand btn-sm btn-bold"
							>
								Sign Out
							</Link>
						</Box>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapStateToProps = ({auth: {user}}) => ({
	user
});

export default connect(mapStateToProps)(UserProfile);
