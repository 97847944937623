import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
}));

const Dashboard = () => {
    const classes = useStyles();
    const [voices, setVoices] = useState([]);
    const [limitAi, setLimitAi] = useState();

    const fetch = () => {
        axios.get('/api/ai/voices').then(({ data }) => setVoices(data));
        axios.get("/api/ai/limits").then(({data}) => {
            setLimitAi(data);
        })
        .catch((err) => console.error(err));
    }

    useEffect( () => {
        fetch();
    }, []);

    return (
        <>
            <h1>Dashboard</h1>
            <h3 style={{color: 'black', marginTop: 40}}>Demo Account</h3>
            {limitAi && <div style={{marginTop: 30}}>
                <h5 style={{color: 'black'}}>Limit voices: {voices.length} / {limitAi.voices_limit}</h5>
                <h5 style={{color: 'black'}}>Limit characters: {limitAi.characters_used} / {limitAi.characters_limit}</h5>
            </div>}
        </>
    );
}

export default Dashboard;
