import React, { useCallback, useEffect, useState } from 'react';
import Button from "widgets/Button.js";
import DataTable from "app/widgets/DataTable";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useFormik } from 'formik';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { TextField, Typography } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Player from "widgets/Player.js";
import Box from '@material-ui/core/Box';
import Loading from 'widgets/Loading.js';
import Grid from "@material-ui/core/Grid";
import Slider from "widgets/Slider.js";
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from "react-redux";
import Checkbox from "widgets/Checkbox.js";

export const AiVoicesPage = () => {
    const [modalAiVoiceOpen, setModalAiVoiceOpen] = useState(false);
    const [modalAiVoiceData, setModalAiVoiceData] = useState({});

    const [voices, setVoices] = useState([]);
    const [limitAi, setLimitAi] = useState();
    const fetch = useCallback(() => 
        {
            axios.get('/api/ai/voices').then(({ data }) => setVoices(data));
            axios.get("/api/ai/limits").then(({data}) => {
                setLimitAi(data);
            })
        }, [])
    const auth = useSelector(({auth}) => auth);

    useEffect(() => {
        fetch()
    }, []);

    return (
        <>
            <DialogAiVoice
                open={modalAiVoiceOpen}
                data={modalAiVoiceData}
                onClose={() => setModalAiVoiceOpen(false)}
                onSubmit={fetch}
            />

            <h1>AI Voices </h1>
            {limitAi && <>
                <h5>Limit voices: {voices.length} / {limitAi.voices_limit}</h5>
                <h5>Limit characters: {limitAi.characters_used} / {limitAi.characters_limit}</h5>
            </>}
            <DataTable
                columns={[
                    {
                        name: 'Name',
                        sortable: true,
                        accessor: 'name',
                    },
                    {
                        name: 'Stabilità',
                        accessor: 'stability',
                        className: 'text-center',
                        Cell: ({cell: {value}}) => `${value} %`
                    },
                    {
                        name: 'Chiarezza',
                        accessor: 'similarity_boost',
                        className: 'text-center',
                        Cell: ({cell: {value}}) => `${value} %`
                    },
                    {
                        name: 'Enfasi',
                        accessor: 'style',
                        className: 'text-center',
                        Cell: ({cell: {value}}) => `${value} %`
                    },
                    {
                        name: 'Actions',
                        sortable: true,
                        accessor: 'actions',
                        className: 'actions text-center',
                        width: '1%',
                        right: true,
                        Cell: ({ row: { original: row } }) => (
                            <>
                                <Button
                                    Icon={<EditIcon />}
                                    color="primary"
                                    tooltip="Edit voice"
                                    onClick={() => {
                                        setModalAiVoiceData(row)
                                        setModalAiVoiceOpen(true)
                                    }}
                                />
                                <Button
                                    Icon={<DeleteIcon />}
                                    color="error"
                                    tooltip="Delete voice"
                                    onClick={() => {
                                        Loading.show();
                                        axios.delete(`/api/ai/voices/${row.id}`)
                                            .then(fetch)
                                            .finally(() => Loading.hide())
                                    }}
                                />

                                <Box p={1}>
                                    <Player
                                        title={row.name}
                                        src={`/api/ai/voices/${row.id}/play`}
                                    />
                                </Box>
                            </>
                        ),
                    },
                ]}
                data={voices}
                HeaderButtons={(
                    <Button variant="contained" color="primary" onClick={() => {
                        setModalAiVoiceData({});
                        setModalAiVoiceOpen(true);
                    }}>
                        Create New
                    </Button>
                )}
            />
        </>
    );
}

const DialogAiVoice = ({ open, data: voice = {}, ...props }) => {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const form = useFormik({
        initialValues: {},
        validate: (values) => {
            const errors = {}

            if (!values.name?.trim()) errors.name = `Il nome è obbligatorio`;
            if (!voice.id) {
                if (values.files.length === 0) errors.files = `Un file è necessario`;
            }

            return errors
        },
        onSubmit: (values) => {
            Loading.show();
            const data = new FormData()
            data.append('name', values.name)
            data.append('stability', values.stability)
            data.append('similarity_boost', values.similarity_boost)
            data.append('style', values.style)
            data.append('use_speaker_boost', values.use_speaker_boost)
            if (values.files) {
                values.files.forEach((file) => data.append('files[]', file.file))
            }
            return axios.post(`/api/ai/voices${voice?.id ? `/${voice.id}` : ''}`, data)
                .then(() => {
                    if (typeof props.onSubmit === 'function') props.onSubmit();
                    if (typeof props.onClose === 'function') props.onClose();
                })
                .finally(() => Loading.hide())
        }
    })

    useEffect(() => {
        if (open) {
            form.resetForm({
                values: {
                    name: '',
                    files: [],
                    stability: 88,
                    similarity_boost: 75,
                    style: 0,
                    use_speaker_boost: true,
                    ...voice,
                }
            })
        }
    }, [open, voice]);

    return (
        <Dialog open={open} {...props} fullWidth>
            <DialogTitle>{voice?.id ? 'Edit AI Voice' : 'Create AI Voice'}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControl className="mb-4">
                        <TextField
                            label="Name"
                            name="name"
                            value={form.values.name}
                            onChange={form.handleChange}
                            variant="outlined"
                        />
                    </FormControl>

                    {!voice?.id && (
                        <FormControl>
                            <FilePond
                                onaddfile={(error, file) => {
                                    form.setFieldValue('files', (form.values.files || []).concat([file]))
                                }}
                            />
                        </FormControl>
                    )}

                    <Grid container alignItems="center" className="mb-4">
                        <Grid item sm={4}>
                            <Typography>Stabilità</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Box display="flex" alignItems="center">
                                <Slider
                                    valueLabelFormat={(label) => `${label} %`}
                                    step={1}
                                    min={0}
                                    max={100}
                                    value={form.values.stability}
                                    onChange={(e, value) => form.setFieldValue('stability', value)}
                                />
                                <Box ml={2}>
                                    <TextField
                                        value={`${form.values.stability} %`}
                                        disabled={true}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="caption">È consigliato un valore maggiore del 30%</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className="mb-4">
                        <Grid item sm={4}>
                            <Typography>Chiarezza</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Box display="flex" alignItems="center">
                                <Slider
                                    valueLabelFormat={(label) => `${label} %`}
                                    step={1}
                                    min={0}
                                    max={100}
                                    value={form.values.similarity_boost}
                                    onChange={(e, value) => form.setFieldValue('similarity_boost', value)}
                                />
                                <Box ml={2}>
                                    <TextField
                                        value={`${form.values.similarity_boost} %`}
                                        disabled={true}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className="mb-4">
                        <Grid item sm={4}>
                            <Typography>Enfasi</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Box display="flex" alignItems="center">
                                <Slider
                                    valueLabelFormat={(label) => `${label} %`}
                                    step={1}
                                    min={0}
                                    max={100}
                                    value={form.values.style}
                                    onChange={(e, value) => form.setFieldValue('style', value)}
                                />
                                <Box ml={2}>
                                    <TextField
                                        value={`${form.values.style} %`}
                                        disabled={true}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="caption">È consigliato un valore minore del 50%</Typography>
                        </Grid>
                    </Grid>
                    <Grid item ml={12}>
                        <Box display="flex" flexDirection={"column"} style={{marginTop: 10, border: '1px solid black', padding: 5, borderRadius: 5, gap: 1, fontSize: '10px'}}>
                            <span style={{fontSize: '11px'}}>Termini e Condizioni</span>
                            <span>
                                La nostra piattaforma ti consente di generare file audio con voci AI sintetiche per scopi personali e commerciali. L'utente dichiara e garantisce di essere il titolare di tutti i diritti necessari per utilizzare la voce che intende clonare con il nostro Servizio. L'utente si impegna a non utilizzare il Servizio per clonare voci senza l'espresso consenso del proprietario dei diritti relativi alla voce originale. <br/>
                                L'utente si impegna a non utilizzare il Servizio per: <br/>
                                - Violare i diritti di proprietà intellettuale o i diritti di privacy di terzi. <br/>
                                - Creare contenuti che siano diffamatori, offensivi, o che incitino all'odio. <br />
                                - Effettuare qualsiasi attività fraudolenta o ingannevole. <br />
                                Utilizzando il Servizio, l'utente conferma di aver compreso e di accettare che deve essere il titolare di tutti i diritti relativi alla voce che intende clonare e di non violare alcuna legge nell'uso del Servizio. <br />
                                Meway non è responsabile per eventuali danni derivanti dall'uso improprio del Servizio o dalla violazione di questi Termini da parte dell'utente. L'utente accetta di manlevare e tenere indenne il nostro Servizio da qualsiasi rivendicazione o richiesta derivante dall'uso del Servizio stesso. <br />
                                <br/>
                                I termini integrali del servizio sono disponibili <a target="_blank" href='https://mashuptool.it/termini-di-servizio/'>qui</a>. <br/>
                            </span> 
                            <Checkbox
								label={
                                    <>
                                       Cliccando su "Accetto" dichiaro di aver letto e compreso i termini di servizio.
                                    </>
                                }
								onChange={(e) => setAcceptedTerms(e.target.checked)}
							/>
                        </Box>
                    </Grid>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button color="success" disabled={!form.dirty || !form.isValid || form.isSubmitting || !acceptedTerms} onClick={form.submitForm}>
                    {voice?.id ? 'Save' : 'Create'}
                </Button>
                <Button color="primary" onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
