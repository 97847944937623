import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";

class InactivityLogout extends Component {
    state = {
        
    }

    componentDidMount = () => {
        const instance = axios.create();

        instance.interceptors.request = axios.interceptors.request;

        this.interval = setInterval(() => {
            instance.get('/api/me').catch((error) => {
                if (error.response.status == 401) {
                    this.props.history.push('/logout');
                    clearInterval(this.interval);
                }
            });
        }, 300000); // every 5 minutes
    }

    render() { 
        return (
            <></>
        );
    }
}
 
export default withRouter(InactivityLogout);
