import React from "react";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: _.max(_.values(theme.zIndex))+1,
		color: theme.palette.common.white,
	},
});

class Loading extends React.PureComponent {
	static Component = CircularProgress;
	static ref = React.createRef();

	state = {
		visible: false,
	};

	static show() {
		Loading.ref.current._show();
	}
	static hide() {
		Loading.ref.current._hide();
	}

	_show = () => {
		this.setState({visible: true});
	};
	_hide = () => {
		this.setState({visible: false});
	};

	render() {
		const {classes} = this.props;
		const {visible} = this.state;

		return (
			<Backdrop open={visible} className={classes.root}>
				<CircularProgress color={"inherit"}/>
			</Backdrop>
		);
	}
}

export default withStyles(styles)(Loading);
