/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {LastLocationProvider} from "react-router-last-location";
import {Helmet} from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";
import {SnackbarProvider} from 'notistack';

import {LayoutSplashScreen} from "./_metronic";

import {Routes} from "./app/router/Routes";
import LogoutOnUnauthorized from './app/LogoutOnUnauthorized';
import TranslateProvider from "./app/lang/TranslateProvider";
import ThemeProvider from "./app/ThemeProvider";
import {PlayerBar, PlayerProvider} from "widgets/Player.js";
import Loading from "widgets/Loading.js";
import Confirm from "widgets/Confirm.js";

import moment from 'moment-timezone';
import 'moment/locale/it';

moment.locale('it');
moment.tz.setDefault('Europe/Rome');

const GlobalCSS = withStyles((theme) => ({
	'@global': {
		'.Mui-disabled img': {
			filter: 'grayscale(100%)',
		},
		'.MuiDialogActions-root .MuiButton-root': {
			fontSize: theme.typography.pxToRem(16),
		}
	},
}))(() => null);

export default function App({store, persistor, basename}) {
	return (
		/* Provide Redux store */
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
				{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
				<React.Suspense fallback={<LayoutSplashScreen/>}>
					{/* Override `basename` (e.g: `homepage` in `package.json`) */}
					<BrowserRouter basename={basename}>
						{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
						<LastLocationProvider>
							{/* Provide Metronic theme overrides. */}
							<ThemeProvider>
								<SnackbarProvider maxSnack={3}>
									{/* Provide `react-intl` context synchronized with Redux state.  */}
									<TranslateProvider>
										<PlayerProvider>
											<>
												<GlobalCSS />
												<PlayerBar/>
												<Loading ref={Loading.ref}/>
												<Confirm ref={Confirm.ref}/>
												<Helmet titleTemplate="MashUP - %s" defaultTitle="MashUP">
													<meta charSet="utf-8"/>
												</Helmet>
												{/* Render routes with provided `Layout`. */}
												<LogoutOnUnauthorized />
												<Routes/>
											</>
										</PlayerProvider>
									</TranslateProvider>
								</SnackbarProvider>
							</ThemeProvider>
						</LastLocationProvider>
					</BrowserRouter>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);
}
