import React from "react";
import {Formik} from "formik";
import {connect} from "react-redux";
import {TextField} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import axios from "axios";
import qs from "querystring";
import _ from 'lodash';

class ResetPassword extends React.PureComponent {
	state = {
		isRequested: false,
		email: '',
		token: '',
	};

	constructor(props) {
		super(props);

		let params = qs.parse(this.props.location.search.replace(/^\?/, ''));

		this.state.email = params.email;
		this.state.token = params.token;
	}

	componentDidMount() {
		if (_.isEmpty(this.state.email) || _.isEmpty(this.state.token)) {
			this.props.history.push('/auth');
		}
	}

	render() {
		const {intl, history} = this.props;
		const {isRequested} = this.state;

		return (
			<div
				className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
				<div className="kt-login__body">
					<div className="kt-login__form">
						<div className="kt-login__title">
							<h3>
								<FormattedMessage id="AUTH.FORGOT.TITLE"/>
							</h3>
						</div>

						<Formik
							initialValues={{
								email: this.state.email || '',
								password: '',
							}}
							validate={values => {
								const errors = {};

								if (!values.email) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.REQUIRED_FIELD"
									});
								}
								else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
								) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.INVALID_FIELD"
									});
								}

								return errors;
							}}
							onSubmit={(values, {setStatus, setSubmitting}) => {
								axios.post('/api/auth/reset-password', {
									email: this.state.email,
									token: this.state.token,
									password: values.password,
								})
									.then(() => {
										history.push(`/auth`);
									})
									.catch(() => {
										setSubmitting(false);
										setStatus({
											type: 'danger',
											message: intl.formatMessage(
												{id: "AUTH.VALIDATION.NOT_FOUND"},
												{name: values.email}
											),
										});
									});
							}}
						>
							{({
								  values,
								  status,
								  errors,
								  touched,
								  handleChange,
								  handleBlur,
								  handleSubmit,
								  isSubmitting
							  }) => (
								<form onSubmit={handleSubmit} className="kt-form">
									{status && (
										<div role="alert" className={`alert alert-${status.type}`}>
											<div className="alert-text">{status.message}</div>
										</div>
									)}

									<div className="form-group">
										<TextField
											type="email"
											label="Email"
											margin="normal"
											fullWidth={true}
											name="email"
											disabled={true}
											value={this.state.email}
										/>
									</div>
									<div className="form-group">
										<TextField
											type="password"
											label="Password"
											margin="normal"
											fullWidth={true}
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password}
											helperText={touched.password && errors.password}
											error={Boolean(touched.password && errors.password)}
										/>
									</div>

									<div className="kt-login__actions">
										<button
											type="submit"
											className="btn btn-primary btn-elevate kt-login__btn-primary"
											disabled={isSubmitting}
										>
											Submit
										</button>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(ResetPassword);
