import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";

class LogoutOnUnauthorized extends Component {
    state = {  }

    componentDidMount = () => {
        axios.interceptors.response.use(
            (response) =>	{
                return response;
            },
            (err) => {
                console.log(err);
                if (err.response.status == 401) {
					// alert('Your session has ended! Please login again!');
					this.props.history.push('/logout');
                }
    
                return Promise.reject(err)
            }
        );
    }

    render() { 
        return (
            <></>
        );
    }
}
 
export default withRouter(LogoutOnUnauthorized);
