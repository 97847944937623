import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import _ from "lodash";
import {connect} from "react-redux";

class MenuSubmenu extends React.Component {
	render() {
		const {item, currentUrl, layoutConfig, auth} = this.props;

		return (
			<ul className="kt-menu__subnav">
				{item.submenu.map((child, index) => {
					if (_.isFunction(child.visible)) {
						if (!child.visible(auth)) return null;
					}

					return (
						<React.Fragment key={index}>
							{child.section && (
								<MenuSection
									item={child}
									parentItem={item}
									currentUrl={currentUrl}
								/>
							)}

							{child.separator && (
								<MenuItemSeparator
									item={child}
									parentItem={item}
									currentUrl={currentUrl}
								/>
							)}

							{child.title && (
								<MenuItem
									item={child}
									parentItem={item}
									currentUrl={currentUrl}
									layoutConfig={layoutConfig}
								/>
							)}
						</React.Fragment>
					);
				})}
			</ul>
		);
	}
}

export default connect(({auth}) => ({auth}))(MenuSubmenu);
