import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MUISlider from "@material-ui/core/Slider";

const Slider = withStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
	},
	thumb: {
		height: 24,
		width: 24,
		marginTop: -8,
		marginLeft: -12,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		whiteSpace: 'nowrap',
		'&:focus,&:hover,&$active': {
			boxShadow: 'inherit',
		},
		'&.Mui-disabled': {
			height: 24,
			width: 24,
			marginTop: -8,
			marginLeft: -12,
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
}))(MUISlider);

Slider.defaultProps = {
	valueLabelDisplay: "auto",
};

export default Slider;
