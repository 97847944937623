import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LayoutSplashScreen} from "../../../_metronic";
import Dashboard from "./Dashboard";
import {AiVoicesPage} from 'app/pages/home/ai/voices/list.js';

const ArchivePage = lazy(() => import('./archive/Archive'));
const MacroListPage = lazy(() => import('./macro/List'));
const MacroEditPage = lazy(() => import('./create/wizard/Wizard'));
const WizardPage = lazy(() => import('./create/wizard/Wizard'));
const ClipEditPage = lazy(() => import('./archive/Edit'));
const ArchiveCategories = lazy(() => import('./archive/Categories'));
const FtpTargetsPage = lazy(() => import('./ftp_targets/ftp_targets'));
const SchedulesPage = lazy(() => import('./schedules/List'));
const RecordingsCreatePage = lazy(() => import('./recordings/create'));
const RecordingsEditPage = lazy(() => import('./recordings/edit'));
const CategoryFilesAdd = lazy(() => import('./archive/CategoryFilesAdd'));

const UsersPage = lazy(() =>
	import('./Users')
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen/>}>
			<Switch>
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/dashboard"/>
				}
				<Route path="/dashboard" component={Dashboard}/>
				{/* <Route path="/archive" component={ArchivePage}/> */}
				<Route path="/users/*" component={UsersPage}/>
				<Redirect exact from="/" to="/dashboard"/>

				<Route exact path="/dashboard" component={Dashboard}/>

				<Redirect exact from="/create" to="/create/wizard"/>
				<Route exact path="/create/wizard" component={WizardPage}/>

				<Route exact path="/categories" component={ArchiveCategories}/>
				<Route exact path="/schedules" component={SchedulesPage}/>

				<Route exact path="/archive/files/edit/:id" component={ClipEditPage}/>
				<Route exact path="/archive/categories" component={ArchiveCategories}/>
				<Route path="/archive/categories/:id/files" component={CategoryFilesAdd}/>

				<Redirect exact path="/archive" to="/archive/clip"/>
				<Route exact path="/archive/:type" component={ArchivePage}/>

				<Redirect exact from="/macro" to="/macro/list"/>
				<Route exact path="/macro/list" component={MacroListPage}/>
				<Route exact path="/macro/edit/:id" component={MacroEditPage}/>

				<Route path="/ftp_targets/*" component={FtpTargetsPage}/>

				<Route path="/recordings/create" component={RecordingsCreatePage}/>
				<Route path="/recordings/edit/:id" component={RecordingsEditPage}/>

				<Route path="/ai/voices" component={AiVoicesPage}/>

				<Redirect to="/error"/>
			</Switch>
		</Suspense>
	);
}
