import React from "react";
import {createTheme} from "@material-ui/core";
import {ThemeProvider as MUIThemeProvider} from "@material-ui/styles";

const theme = createTheme(
	/**
	 * @see https://material-ui.com/customization/themes/#theme-configuration-variables
	 */
	{
		typography: {
			fontFamily: ["Poppins", "Roboto"].join(","),
            fontSize: 14,
		},

		palette: {
			contrastThreshold: 2,
			primary: {
				main: "#5d78ff"
			},
			secondary: {
				main: "#0abb87",
				contrastText: "#ffffff"
			},
			error: {
				main: "#fd397a"
			}
		},

		/**
		 * @see https://material-ui.com/customization/globals/#default-props
		 */
		props: {
			MuiButtonBase: {
				disableRipple: true,
			},

			MuiButton: {
				variant: 'outlined',
			},

			MuiPopover: {
				elevation: 1
			},

			MuiFormControl: {
				margin: 'dense',
			},

			MuiTextField: {
				variant: 'outlined'
			},

			MuiRadio: {
				color: 'primary',
			},
			MuiCheckbox: {
				color: 'primary',
			},
		}
	}
);

export {theme};
export default function ThemeProvider({children}) {
	return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
