import React from "react";
import MUIDialog from "@material-ui/core/Dialog";
import MUIDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/styles/withStyles";
import {Close} from "@material-ui/icons";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
	title: {
		margin: 0,
	},
	closeWrapper: {
		marginLeft: theme.spacing(2),
		paddingRight: theme.spacing(-1),
	},
	close: {
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)(({children, classes, onClose, ...props}) => {
	return (
		<MUIDialogTitle disableTypography className={classes.title} {...props}>
			<Box display={'flex'} alignItems={'center'}>
				<Typography variant={'h5'}>{children}</Typography>
				<Box flexGrow={1}/>
				{onClose && (
					<Box className={classes.closeWrapper}>
						<IconButton aria-label="close" className={classes.close} onClick={onClose}>
							<Close />
						</IconButton>
					</Box>
				)}
			</Box>
		</MUIDialogTitle>
	);
});

export default function Dialog({title, children, onClose, ...props}) {
	return (
		<MUIDialog onClose={onClose} {...props}>
			<DialogTitle onClose={onClose}>
				{title}
			</DialogTitle>
			{children}
		</MUIDialog>
	)
}



Dialog.propTypes = {
	...MUIDialog.propTypes,
};
