import React from "react";
import {v4} from "uuid";
import _ from "lodash";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSelector} from "react-redux";
import WaveSurfer from 'wavesurfer.js';
import IconButton from "@material-ui/core/IconButton";
import {Close, Pause, PlayArrow} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {grey, red} from "@material-ui/core/colors";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import MUILinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Slider from "widgets/Slider.js";
import {connect} from "react-redux";

function usePrevious(value) {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}

function formatTime(time) {
	if (!time) return '00:00';

	let hours = Math.floor(time / 3600);
	let minutes = Math.floor((time - (hours * 3600)) / 60);
	let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));

	if (hours <= 0) {
		hours = '';
	}

	minutes = `${minutes < 10 ? '0' : ''}${minutes}`;
	seconds = `${seconds < 10 ? '0' : ''}${seconds}`;

	return _.join(_.compact([hours, minutes, seconds]), ':');
}


class PlayerBarComponent extends React.PureComponent {
	state = {
		visible: false,
		height: 50,
		duration: 0,
		progress: 0,
	};

	player = React.createRef();

	componentDidMount() {
		const {context} = this.props;

		PlayerService.close = this.close;
		PlayerService.play = (params) => {
			context.play({
				...params,
			});
		};
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		let changed = false;

		if (this.props.context && prevProps.context && this.props.context.src !== prevProps.context.src) {
			console.debug('@Player', 'loading');
			this.props.context.setState(PLAYER_STATE.loading);

			this.setState({
				src: this.props.context.src,
				ready: false,
			});

			changed = true;
		}

		if (this.props.context.is_playing !== prevProps.context.is_playing) {
			if (this.props.context.is_playing) {
				this.setState({visible: true, src: this.props.context.src});

				if (this.state.ready && !changed) {
					this.play();
				}
			}
			else {
				this.pause();
			}
		}

		if (this.props.location !== prevProps.location) {
			this.close();
		}
	}

	onReady = ({target: audio}) => {
		console.debug('@Player', 'ready');
		const {setIsPlaying, setState} = this.props.context;

		if (this.state.seeking) return;

		this.setState({
			ready: true,
			duration: audio.duration,
		});
		setState(PLAYER_STATE.ready);
		setIsPlaying(true);

		this.play();
	};
	onLoading = () => {
		console.debug('@Player', 'loading');
		const {setState} = this.props.context;

		this.setState({
			ready: false,
		});

		setState(PLAYER_STATE.loading);
	};
	onPlay = () => {
		console.debug('@Player', 'play');
		const {setState} = this.props.context;

		setState(PLAYER_STATE.playing);
	};
	onPause = () => {
		console.debug('@Player', 'pause');
		const {setState} = this.props.context;

		setState(PLAYER_STATE.paused);
	};
	onFinish = () => {
		console.debug('@Player', 'finish');
		const {setState, setIsPlaying} = this.props.context;

		setIsPlaying(false);
		setState(PLAYER_STATE.idle);

		this.setState({
			progress: this.state.duration,
		});
	};
	onError = (e) => {
		console.debug('@Player', 'error', e);
		const {setState} = this.props.context;

		setState(PLAYER_STATE.idle);
	};
	onProgress = (progress) => {
		console.debug('@Player', 'progress', progress);
		const {setState} = this.props.context;

		this.setState({
			progress,
		});

		if (progress > 0 && progress < this.state.duration) {
			setState(PLAYER_STATE.playing);
		}
	};
	onSeek = (seek) => {
		console.debug('@Player', 'seek', seek);

		this.setState({
			progress: _.get(this.player, 'current.audioEl.current.currentTime', 0),
		});
	};
	onDestroy = () => {
		console.debug('@Player', 'destroy');
		const {setState} = this.props.context;

		setState(PLAYER_STATE.idle);
		this.setState({
			ready: false,
		});
	};

	play = () => {
		_.invoke(this.player, 'current.audioEl.current.play');
	};
	pause = () => {
		_.invoke(this.player, 'current.audioEl.current.pause');
	};
	stop = () => {
		this.setState({
			src: undefined,
			duration: 0,
			progress: 0,
		});
	};
	seek = (value) => {
		_.set(this.player, 'current.audioEl.current.currentTime', value);
	};

	close = () => {
		const {setState, setIsPlaying} = this.props.context;

		this.setState({
			visible: false,
		});

		setIsPlaying(false);
		setState(PLAYER_STATE.idle);

		this.stop();
	};

	onProgressChange = (e, value) => {
		this.setState({
			seeking: true,
		}, () => {
			this.pause();

			this.seek(value);
		});
	};
	onProgressChanged = (e, value) => {
		this.setState({
			seeking: false,
		}, () => {
			this.play();
		});
	};

	render() {
		const {classes, auth} = this.props;
		const {visible, progress, duration, source, height, src} = this.state;
		const {title, state, data, setState, is_playing, setIsPlaying} = this.props.context;

		const qs = _.qs({
			...data,
			token: auth.authToken,
		});

		return (
			<Box className={clsx(classes.root, {'open': visible,})}>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
					<Box px={1}>
						<Typography component={'span'} variant={'h5'}>{title}</Typography>
					</Box>
					<IconButton
						onClick={this.close}
						color={"inherit"}
					>
						<Close color={"inherit"}/>
					</IconButton>
				</Box>
				<Box display={'flex'} alignItems={'center'} m={1} height={height}>
					<Box>
						<PlayerIcon/>
					</Box>
					<Box className={classes.time}>
						{formatTime(progress)}
					</Box>
					<Box flexGrow={1} position={'relative'}>
						{state === PLAYER_STATE.loading ? (
							<Box position={'absolute'} display={'flex'} alignItems={'center'} width={'100%'} height={'100%'}>
								<LinearProgress className={classes.progress} style={{width: '100%'}}/>
							</Box>
						) : (
							<Box px={1}>
								<Slider
									step={.1}
									min={0}
									max={duration}
									value={progress}
									onChangeCommitted={this.onProgressChanged}
									onChange={this.onProgressChange}
									valueLabelDisplay={'off'}
								/>
							</Box>
						)}

						{src && (
							<ReactAudioPlayer
								ref={this.player}
								src={src ? `${src}?${qs}` : null}
								listenInterval={250}
								onPlay={this.onPlay}
								onPause={this.onPause}
								onEnded={this.onFinish}
								onSeeked={this.onSeek}
								onError={this.onError}
								onListen={this.onProgress}
								onCanPlay={this.onReady}
							/>
						)}
					</Box>
					<Box className={classes.time}>
						{formatTime(duration)}
					</Box>
				</Box>
			</Box>
		);
	}
}
PlayerBarComponent = withStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		zIndex: 1250,
		overflow: 'hidden',
		'&:not(.open)': {
			transform: 'translateY(100%)',
		}
	},
	time: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		fontFamily: 'Roboto',
		fontSize: theme.typography.pxToRem(20),
		fontWeight: 'bold',
	},



	wrapper: {
		position: 'relative',
	},
	wave: {
		width: '100%',
		margin: 0,
		padding: 0,
	},
	icon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		boxShadow: theme.shadows[2],
		transitionDuration: theme.transitions.duration.standard,
		transitionProperty: 'background-color',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: theme.shadows[4],
		},
	},
	loading: {
		color: red[500],
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
}))(withRouter(connect(({auth}) => ({auth}))(PlayerBarComponent)));

const PlayerBar = React.forwardRef((props, ref) => (
	<PlayerContext.Consumer>
		{(context) => (
			<PlayerBarComponent ref={ref} context={context} {...props}/>
		)}
	</PlayerContext.Consumer>
));

const LinearProgress = withStyles(() => ({
	colorPrimary: {
		backgroundColor: red[800],
	},
	barColorPrimary: {
		backgroundColor: red[500],
	},
}))(MUILinearProgress);

const PlayerContext = React.createContext();

const PLAYER_STATE = {
	idle: 'idle',
	playing: 'playing',
	paused: 'paused',
	ready: 'ready',
	loading: 'loading',
};

const PlayerProvider = function ({children}) {
	const [id, setId] = React.useState();
	const [options, setOptions] = React.useState({});
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [state, setState] = React.useState(PLAYER_STATE.idle);

	const play = React.useCallback(({id, title = '', data = {}, ...options}) => {
		if (id) {
			setId(id);
		}
		setOptions((o) => ({...o, title, data, ...options}));
		setIsPlaying(true);
	}, [setId, setOptions, setIsPlaying]);

	const pause = React.useCallback(() => {
		setIsPlaying(false);
	}, [setIsPlaying]);

	const toggle = React.useCallback(({...props}) => {
		if (isPlaying && ((props.id && id === props.id) || !props.id)) {
			pause({...props});
		}
		else {
			play({...props});
		}
	}, [id, isPlaying, play, pause]);

	return (
		<PlayerContext.Provider
			value={{
				...options,
				is_playing: isPlaying,
				id,
				play,
				pause,
				toggle,
				state,
				setState,
				setIsPlaying,
			}}
		>
			{children}
		</PlayerContext.Provider>
	);
};

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
	},
	icon: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		boxShadow: theme.shadows[2],
		transitionDuration: theme.transitions.duration.standard,
		transitionProperty: 'background-color',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			boxShadow: theme.shadows[4],
		},
	},
	loading: {
		color: red[500],
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
}));

const PlayerIcon = function ({id, active = true, size = 'medium', ...props}) {
	const classes = useStyles();
	const {state, ...context} = React.useContext(PlayerContext);
	const auth = useSelector(({auth}) => auth);

	if (id) {
		active = id === context.id;
	}

	const play = React.useCallback(() => {
		context.play({...props, id});
	}, [context.play, props, id]);
	const pause = React.useCallback(() => {
		context.pause();
	}, [context.pause]);
	const toggle = React.useCallback(() => {
		context.toggle({...props, id});
	}, [context.toggle]);

	let icon = <PlayArrow/>;
	if (active) {
		switch (state) {
			case PLAYER_STATE.loading: icon = <PlayArrow />; break;
			case PLAYER_STATE.playing: icon = <Pause/>; break;
		}
	}

	return (
		<div className={classes.wrapper} style={{overflow: 'visible'}}>
			<IconButton
				className={classes.icon}
				onClick={toggle}
				size={size}
			>
				{icon}
			</IconButton>
			{active && state === PLAYER_STATE.loading && <CircularProgress size={'100%'} className={classes.loading} />}
		</div>
	);
};

const PlayerService = {};

const Player = function ({size, ...props}) {
	return (
		<>
			<PlayerIcon id={props.src} size={size} {...props}/>
		</>
	)
};
Player.defaultProps = {
	size: 'small',
};
Player.propTypes = {
	src: PropTypes.string.isRequired,
	title: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export {
	PlayerProvider,
	PlayerBar,
	PlayerService,
};
export default Player;
